import Vue from 'vue'
import Router from 'vue-router'
Vue.use(Router)

// 解决ElementUI导航栏中的vue-router在3.0版本以上重复点菜单报错问题
const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

export default new Router({
    routes: [

        {
            path: '/',
            redirect: '/home'
        },

        {
            path: '/home',
            name: 'home',
            component: () =>
                import ('../view/home/Index'),
            children: [{
                    path: '/home',
                    name: 'main',
                    component: () =>
                        import ('../view/Main/index.vue')
                },
                {
                    path: '/iot',
                    name: 'iot',
                    component: () =>
                        import ('../view/Link_Iot/index.vue')
                }, {
                    path: '/view',
                    name: 'view',
                    component: () =>
                        import ('../view/Link_View/index.vue')
                }, {
                    path: '/scada',
                    name: 'scada',
                    component: () =>
                        import ('../view/Link_Scada/index.vue')
                }, {
                    path: '/smart',
                    name: 'smart',
                    component: () =>
                        import ('../view/Link_Smart/index.vue')
                }, {
                    path: '/edge',
                    name: 'edge',
                    component: () =>
                        import ('../view/Link_Edge/index.vue')
                },

                {
                    path: '/home/contactUs',
                    name: 'contactUs',
                    component: () =>
                        import ('../view/contactUs/Index.vue'),

                },
                {
                    path: '/home/aboutLink',
                    name: 'aboutLink',
                    component: () =>
                        import ('../view/aboutLink/Index.vue'),

                },

                {
                    path: '/home/smartWater',
                    name: 'smartWater',
                    component: () =>
                        import ('../view/smartWaterSolution/Index.vue'),
                },

                {
                    path: '/home/gas',
                    name: 'gas',
                    component: () =>
                        import ('../view/gasSolution/Index.vue'),
                },
                {
                    path: '/home/newSource',
                    name: 'newSource',
                    component: () =>
                        import ('../view/newPowerSource/Index.vue'),
                }
            ]
        },

    ]
})