<template>
  <div :style="styles" class="show" ref="show">
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    styles: {},
    moveData: {},
  },
  name: "show",
  data() {
    return {};
  },
  methods: {},
  mounted() {
    //视图可见 加载动画
    const observer = new IntersectionObserver((dom) => {
      dom.forEach((item) => {
        if (item.isIntersecting) {
          let _target = item.target;
          let { left, time } = this.moveData;
          _target.style.left = left;
          _target.style.opacity = "1";
          _target.style.transition = time ? time : ".8s";
          observer.unobserve(_target);
        }
      });
    });
    observer.observe(this.$refs.show);
  },
};
</script>

<style scoped lang="less">
.show {
  position: absolute;
  opacity: 0;
}
</style>
