<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "App",
};
</script>

<style>
/* 公共样式 */
@import url("./assets/styles/common.less");
#app {
  width: 100%;
  min-width: 1316px !important;
}
</style>
