import Vue from 'vue'
import App from './App.vue'
import router from './router'
//引入组件
import VueParticles from 'vue-particles'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

import moment from 'moment'
import vueSeamlessScroll from "vue-seamless-scroll";
import echarts from 'echarts'
import show from './components/common'
import bus from "vue-bus"
Vue.use(show)
Vue.use(vueSeamlessScroll)
    //全局注册/使用
Vue.use(VueParticles)
Vue.use(ElementUI)
Vue.prototype.$moment = moment
Vue.prototype.$echarts = echarts

Vue.use(bus)

import {
    Message
} from 'element-ui'

Vue.config.productionTip = false

Vue.prototype.defaultDate = function(type) {
    return moment(new Date()).format(type) //默认当前日期
}

//时间统一处理---全局过滤器  时间过滤
Vue.filter("dateFmt", (input, formatString = "YYYY-MM-DD HH:mm:ss") => {
    return moment(input).format(formatString)
})

//路由守卫
// router.beforeEach((to, from, next) => {
//     //有token信息 已登陆
//     if (sessionStorage['token']) {
//         next()
//             //没有token信息 跳转登录页面
//     } else {
//         if (to.path == '/login') {
//             next()
//         } else {
//             next('/login')
//         }
//     }
// })

// 监听session
let orignalSetItem = window.sessionStorage.setItem; // 原生localStorage.setItem方法
sessionStorage.setItem = function(key, newValue) {
    let setItemEvent = new Event("setItemEvent"); // 重写注册setItem
    setItemEvent.key = key;
    setItemEvent.value = newValue;
    window.dispatchEvent(setItemEvent); // 派发setItem
    orignalSetItem.apply(this, arguments); // 设置值
}

new Vue({
    router,
    render: h => h(App),
}).$mount('#app')